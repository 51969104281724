import merge from "lodash-es/merge";
import pick from "lodash-es/pick";

import { toZoneISOString } from "./datetime";
import { api, auth, client } from "./auth";
import { throttle } from "lodash-es";

export { api };

// update auth header as auth changes
export let user = "self";
export let authHeader = "";
export let credentials;

auth.subscribe(function ($auth) {
  credentials = $auth;
  authHeader = $auth && `&Authorization=${$auth.type} ${$auth.token}`;
  user = $auth.sub || "self";
});

export function authorize(url) {
  if (typeof url === "string") url = new URL(url);
  if (url) {
    url.searchParams.set(
      "Authorization",
      (credentials && `${credentials.type} ${credentials.token}`) || ""
    );
    return url;
  }
  return "&Authorization=" + (authHeader || "");
}

export function viewpoint(offset) {
  return encodeURIComponent(
    toZoneISOString(new Date().getTime() + (offset || 0))
  );
}

const apiVersion = "v2";

export function base() {
  return api.settings.apiBase + apiVersion;
}

function formDataToURLSearchParams(formData) {
  return new URLSearchParams([...formData.entries()]); // what edge does this support?
}

export function coordsToURLSearchParams(coords) {
  return new URLSearchParams(
    Object.entries(
      pick(coords || {}, [
        "latitude",
        "longitude",
        "accuracy",
        "altitude",
        "altitudeAccuracy",
        "speed",
        "heading",
        "headingAccuracy",
      ])
    )
      .filter(([a, b]) => !!b)
      .map(([a, b]) => [a, b + ""])
  );
}

export async function fetchProperties() {
  const json = await Promise.all([
    fetch(
      authorize(
        `${base()}/properties?viewpoint=${viewpoint()}&principal=${user}`
      )
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  for (const [k, v] of Object.entries(json.properties.items)) {
    const item = (json.properties.items[k] =
      json.items[v] || json.items[k] || v);

    for (const k2 of ["address"]) {
      if (item[k2]) item[k2] = json.items[item[k2]] || item[k2];
    }
  }

  return json;
}

<script context="module">
  import { authorize, fetchProperties, user } from "./util/api";
  import { format as formatTZ } from "date-fns-tz";

  export function toZoneISOString(local, tz) {
    //console.log(local, tz);
    //if(!tz) return local.toISOString();
    return formatTZ(local, "yyyy-MM-dd'T'HH:mm:ssxxx", { timeZone: tz });
  }

  export function viewpoint(offset) {
    return encodeURIComponent(
      toZoneISOString(new Date().getTime() + (offset || 0))
    );
  }
</script>

<ul>
  {#each Object.entries( { [authorize(`https://api.propertyboss.io/v2/users/${user}`)]: "Get Current User", [authorize(`https://api.propertyboss.io/v2/authorizations?principal=${user}&viewpoint=${viewpoint()}`)]: "Get Current User Authorizations", [authorize(`https://api.propertyboss.io/v2/properties?principal=${user}`)]: "All Properties for Current User" } ) as [url, name]}
    <li><a href={url} target="_blank">{name}</a></li>
  {/each}
</ul>

<h1>Current User Properties</h1>
{#await fetchProperties()}
  Loading properties&hellip;
{:then data}
  <ul>
    {#each Object.entries( { [authorize(`https://api.propertyboss.io/v2/payments/collected/metrics?viewpoint=${viewpoint()}&interval=2022-08-01T00:00:00/2022-09-01T00:00:00${Object.keys(data.properties.items).reduce( (s, id) => {
              return s + "&scope=" + id;
            }, "" )}&payments=total&payments=net&metric=total&metric=property`)]: "Properties Payments Metrics" } ) as [url, name]}
      <li><a href={url} target="_blank">{name}</a></li>
    {/each}
  </ul>
  <ul>
    {#each Object.values(data.properties.items) as property}
      <li>
        <h1>{property.name}</h1>
        {#each Object.entries( { [authorize(`https://api.propertyboss.io/v2/permits?for=${property.id}&viewpoint=${viewpoint()}&valid=${viewpoint()}/${viewpoint()}`)]: "Permits", [authorize(`https://api.propertyboss.io/v2/violations?for=${property.id}&files=true&viewpoint=${viewpoint()}&interval=2022-07-17T00%3A00%3A00/`)]: "Violations", [authorize(`https://api.propertyboss.io/v2/units?scope=${property.id}&viewpoint=${viewpoint()}`)]: "Units", [authorize(`https://api.propertyboss.io/v2/units/tenants?scope=${property.id}&viewpoint=${viewpoint()}&valid=${viewpoint()}/${viewpoint()}`)]: "Unit Tenants", [authorize(`https://api.propertyboss.io/v2/vehicles/permits/summary?scope=${property.id}&viewpoint=${viewpoint()}&valid=${viewpoint()}/${viewpoint()}`)]: "Vehicle Permits Summary" } ) as [url, name]}
          <li><a href={url} target="_blank">{name}</a></li>
        {/each}
      </li>
    {/each}
  </ul>
{/await}

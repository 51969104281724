import App from "./App.svelte";
import { api } from "./util/auth";
import { router, end } from "./util/router";

import style from "../styles/app.scss";

const app = new App({
  target: document.querySelector("body"),
});

router("/", end);

router.start({
  dispatch: true,
  popstate: true,
  click: true,
  hashbang: false,
});
